import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    state: () => ({
        user: null,
        userQuestionnaire: null
    }),
    actions: {
        setUser(user) {
            console.log('setting user...')
            if (user) {
                this.user = user
            }
        },
        setUserQuestionnaire(questionnaire) {
            console.log('setting questionnaire...')
            this.userQuestionnaire = questionnaire
        }
    }
})
